.home-container {
  h1 {
    font-family: "nunito_sansbold";
    color: $ruuby-grey;
  }

  h2 {
    font-family: "nunito_sansbold";
    color: $ruuby-grey;
    font-size: 2em;
  }

  .tasks {
    @include make-lg-column(6);
    padding-left: 0;
  }
}