.react-datepicker__input-container {
  flex-grow: 2;

  input {
    @extend .form-control;
  }
}

.react-datepicker__time-box {
  display: inline-block;
  width: 90px;
  margin-left: 5px;
}
