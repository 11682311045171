@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss";
@import "./react-modal";
@import "./react-datepicker";

@import "../../node_modules/react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
@import "./fonts";
@import "./variables";
@import "../components/booking/styles";
@import "../components/booking/adjustments/styles";
@import "../components/sub/alerts/styles";
@import "../components/customer-panel/styles";
@import "../components/customers/styles";

@import "../containers/home-container/styles";
@import "../containers/booking-container/styles";
@import "../containers/booking-notes-container/styles";

@import "./therapist";

html {
  font-size: 100%;
  body {
    overflow-x: hidden;
    background: $ruuby-grey-bg;
  }
}

.header-link-active {
  color: #555 !important;
  background-color: #e7e7e7 !important;
}

.btn-primary {
  color: #fff;
  background-color: $ruuby-blue-dark;
  border-radius: 0;
  font-family: 'nunito_sanssemibold';
}

.detail-heading {
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin: 2px 0;
}

.detail-job-heading,
.detail-region-heading,
.detail-partner-heading {
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin: 2px 0;
  color: #999999;
  text-align: left; 
}

.detail-value {
  margin: 3px 0;
  font-size: 15px;
}

.detail-job-value,
.detail-region-value,
.detail-partner-value {
  margin: 3px 0;
  font-size: 15px;
}

.detail-job-value input[type=text], 
.detail-region-value input[type=text],
.detail-region-value input[type=number],
.detail-partner-value input[type=text],
.detail-partner-value input[type=password],
.detail-partner-value input[type=number],
.detail-partner-value select,
.detail-partner-value textarea,
.detail-job-value textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #dedede;
}

.detail-partner-value .select-box {
  text-align: left;
}

.status-cancelled {
  @include label-variant($status-cancelled);
  text-transform: uppercase;
}

.status-unconfirmed {
  @include label-variant($status-unconfirmed);
  text-transform: uppercase;
}

.status-confirmed {
  @include label-variant($status-confirmed);
  text-transform: uppercase;
}

.status-completed {
  @include label-variant($status-completed);
  text-transform: uppercase;
}

.status-therapist_on_way {
  @include label-variant($status-on-way);
  text-transform: uppercase;
}

.status-in_progress {
  @include label-variant($status-in-progress);
  text-transform: uppercase;
}

.badge {
  text-transform: uppercase;
  vertical-align: middle;
  padding: 5px;
  margin: 0 10px;

  &.badge-production {
    background-color: #008800;
  }
}

h1 {
  .badge {
    font-size: 1.25rem;
  }

  .status-cancelled, .status-unconfirmed, .status-confirmed, .status-completed {
    font-size: 1.25rem;
  }
}

.field-edit {
  display: flex;
  min-width: 50px;
  white-space: pre-line;

  p {
    min-height: 20px;
    margin: 0;
    display: inline-block;
  }

  .empty {
    color: rgba(0, 0, 0, 0.5);
  }

  .edit-icon {
    margin-left: 10px;
    margin-top: 2px;
  }

  .edit-icon:hover {
    cursor: pointer;
  }

  .edit-icon-hover {
    display: none;
  }

  &:hover {
    .edit-icon-hover {
      display: inline;
    }
  }

  .select-box {
    flex: auto;
  }

  .input-box {
    width: 100%;
    display: inline-flex;
    input, textarea {
      flex-grow: 2;
      min-width: 50px;
    }
  }

  .form-control {
    padding: 5px 2px;
  }

  .btn {
    max-height: 35px;
  }

  .btn-submit {
    @include button-variant(#5cb85c, white, #ccc);
    margin-left: 5px;
  }

  .btn-cancel {
    @include button-variant(white, #d9534f, #d43f3a);
    margin-left: 5px;
    background: $ruuby-red;
  }

  &.new-treatment-field {
    float: right;
    margin-bottom: 15px;

    .Select-control {
      min-width: 450px;
    }
  }

  &.field-edit-status {
    .Select {
      width: 300px;
      span {
        font-size: 1em;
        font-weight: normal;
      }
    }
  }

  &.spacer {
    margin-bottom: 20px;
  }
}

.newTreatment {
  select {
    min-width: 80px;
  }
}

.treatment-row {
  .btn-delete {
    @include button-variant(white, #d9534f, #d43f3a);
    margin-left: 5px;
    background: $ruuby-red;
    border-color: $ruuby-red;
    padding: 3px 7px 3px;
  }
}

// react-select library
.Select {
  flex-grow: 2;
}

.tags {
  margin: 20px 0;

  .tag-ruuby-on-demand {
    @include label-variant(#49c);
    font-size: 0.9em;
  }
}

.promotion-large-cell {
  width: 180px;
}

.promotion-medium-cell {
  width: 120px;
}

.main-promotion-details-row.float-right {
  float: right;
}

.float-right {
  float: right;
}

.promotion-actions-cell {
  width: 180px; 
  text-align: right;
}

.promotion-small-cell {
  width: 100px;
}

.clear-both {
  clear: both;
}

.main-region-details-cell {
  margin-top: 20px;
  width: 50%;
}

.main-partner-details-cell,
.main-partner-details-cell-right {
  float: left;
  width: 48%;
  margin-top: 20px;
}

.main-partner-details-cell-right {
  margin-left: 4%;
}

.main-job-details-row,
.main-region-details-row,
.main-partner-details-row,
.main-partner-details-row-sm {
  margin-bottom: 20px;
}

.main-partner-details-row-sm {
  float: left;
  width: 50%;
  margin-bottom: 26px;
}

.main-promotion-details-row {
  // margin-bottom: 20px;
  margin-top: 20px;
  width: 290px;
  float: left;
}

.btn-create-job {
  width: 100px;
  background-color: #00AF6C;
  border-radius: 0px;
  border-color: #4CAF50;
  color: #ffffff;
}

.btn-create-job:hover {
  background-color: #00AF6C;
  border: 1px;
  color: #ffffff;
}

.btn-cancel-job {
  width: 100px;
  background-color: #cccccc;
  border-radius: 0px;
  border-color: #cccccc;
  color: #666666;
  margin-left: 20px;
}

.btn-cancel-job:hover {
  background-color: #cccccc;
  border: 1px;
  color: #666666;
}

.app-details-row {
  @include make-row(15px);

  .main-details {
    @include make-lg-column(8);
    padding: 10px;

    .main-details-row {
      @include make-row;
      margin-bottom: 7px;

      .booking-date, .booking-time, .therapist-commission, .therapist-recommended, .therapist-booking-lead-time, .main-details-section {
        @include make-lg-column(6);
      }

      .booking-salon, .therapist-description {
        @include make-lg-column(12);
      }

      .booking-notes {
        @include make-lg-column(12);

        .empty {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .booking-more {
        display: inline-block;
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
      }

      .therapist-recommended {
        p {
          font-weight: 100;
          display: inline-block;
          font-size: .8em;
          padding-left: 5px;
        }
      }

      .booking-address {
        @include make-lg-column(12);
        margin-top: 0;
      }
    }

    .field-edit {
      min-height: 25px;
    }
  }

  .customer {
    @include make-lg-column(4);
    padding: 10px;

    .booking-b2b {
      margin-top: 7px;
    }
  }

  .therapist-imgs {
    @include make-lg-column(4);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    .customer-details-row {
      @include make-row;
      padding: 0 15px;

      .therapist-img, .img-dropzone {
        @include make-md-column(3);
        padding: 0 5px;
      }

      .therapist-img {
        .image_overlay {
          display: none;
          position: absolute;
          height: 100%;
          width: 100%;
          .spinner {
            position: absolute;
            width: 60%;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            z-index: 100;
          }
          .overlay_bg {
            background: rgba(255,255,255,.5);
            width: 100%;
            height: 100%;
          }
        }
        .button-wrapper {
          position: absolute;
          button {
            color: white;
            margin-right: 2px;
            margin-top: 2px;
            font-size: 1.1em;
            display: none;
            &.edit {
              background-color: green;
            }
            &.delete {
              background-color: red;
            }
          }
        }
        &:hover {
          .button-wrapper {
            button {
              display: inline-block;
            }
          }
        }
        &.attempting_delete {
          .image_overlay {
            display: block;
          }
        }
      }
      .img-dropzone {
        div {
          width: 100% !important;
          height: 100px !important;
          color: grey;
          border-color: grey !important;
          text-align: center;
          font-size: 3em;
          padding-top: .5em;
          p {
            font-size: .4em;
          }
        }
        .spinner {
          .pulse {
            width: 50px !important;
            height: 50px !important;
            margin-left: calc(calc(100% - 50px)/2);
          }
        }
        &:hover {
          div {
            color: black !important;
            border-color: black !important;
            cursor: pointer;
          }
        }
      }

      .img-update-row {
        button {
          margin-top: 10px;
        }
      }
    }
  }
}

.booking-address {
  margin-top: 15px;
}

.customer-edit {
  h4 {
    margin-top: 0;
  }
}

.treatments-header, .therapist-header {
  margin-top: 10px;
  .detail-heading {
    display: inline-block;
    margin-top: 0;
  }
  button {
    float: right;
    &.new-treatment-btn {
      margin-bottom: 15px;
    }
  }
}

.therapist-availability {
  .therapist-header {
    height: 50px;

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          font-size: 2em;
          font-weight: bold;
          border: none;
          box-shadow: none;
          z-index: 100;
          position: relative;
          text-align: left;
          background: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .availability-list {
    position: relative;
    .availability-row {
      position: relative;
      padding-left: 5px;
      height: 40px;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      cursor: pointer;

      span.time {
        position: absolute;
        left: 10px;
        top: 5px;
        color: #505766;
        font-size: 1.7em;
        font-weight: bold;
        cursor: inherit;
      }

      &:before {
        font-size: .9em;
        margin-top: 10px;
        display: block;
        text-align: right;
        padding-right: 10px;
      }
      &.disabled {
        cursor: default;
        span.time {
          color: #dddddd;
        }
      }
      &.available {
        background: #e9c5b3;
        &:before {
          content: "AVAILABLE";
          color: #505766;
        }
      }
      &.scheduled {
        background: #fbece9;
        &:before {
          content: "SCHEDULED";
          color: #505766;
        }      
      }
      &.unavailable {
        background: #505766;
        &:before {
          content: "UNAVAILABLE";
          color: #FFF;
        }
        span.time {
          color: #FFF;
        }          
      }
      &.holiday {
        background: #757B89;
        cursor: default;
        &:before {
          content: "HOLIDAY";
          color: #FFF;
        }
        span.time {
          color: #FFF;
        }          
      }
      &.updating {
        &:before {
          content: "UPDATING";
        }
      }
      p {
        font-weight: bold;
        color: rgb(91, 105, 119);
        margin-bottom: 5px;
        span.price {
          font-weight: 300;
        }
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.button-delete {
  @extend .btn;
  @include button-variant(white, #d9534f, #d9534f);
  max-height: 35px;
  max-width: 35px;
  margin-right: 5px;
  background: $ruuby-red;
  border-radius: 0;
  border-color: $ruuby-red;
}

.app-table {
  thead {
    tr {
      th {
        border-top: none;
        text-transform: uppercase;
        font-family: "nunito_sansregular";
        color: $ruuby-grey;
        font-size: 0.8em;
        font-weight: normal;
      }
    }
  }

  .table-row {
    font-size: 0.8rem;
    font-family: "nunito_sansregular" !important;

    &.unpaid {
      background-color: $booking-unpaid;
      &:hover {
        background-color: darken($booking-unpaid, 2%) !important;
      }
    }
    &.THERAPIST_ON_WAY {
      background-color: lighten($status-on-way, 40%) !important;
      &:hover {
        background-color: lighten($status-on-way, 35%) !important;
      }
    }
    &.IN_PROGRESS {
      background-color: lighten($status-in-progress, 40%) !important;
      &:hover {
        background-color: lighten($status-in-progress, 35%) !important;
      }
    }
    &.COMPLETED {
      background-color: lighten($status-completed, 55%) !important;
      &:hover {
        background-color: lighten($status-completed, 50%) !important;
      }
    }
    &.UNCONFIRMED {
      background-color: lighten($status-unconfirmed, 40%) !important;
      &:hover {
        background-color: lighten($status-unconfirmed, 35%) !important;
      }
    }
    &.CONFIRMED {
      background-color: lighten($status-confirmed, 35%) !important;
      &:hover {
        background-color: lighten($status-confirmed, 30%) !important;
      }
    }

    td {
      &.paid-section {
        text-align: center;
        .label {
          background: none;
          font-size: 2em;
          &.PAID {
            color: $booking-paid;
          }
        }
      }
      &.labels {
        text-align: right;

        .fa {
          margin-left: 5px;
        }
      }
    }

  }

  .table-row:hover {
    background-color: #efefef !important;
    cursor: pointer;
  }
}

.search-toolbar {
  border: rgba(0, 0, 0, 0.25) 1px solid;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  overflow: auto;

  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;

  .form-label {
    font-size: 0.7rem;
  }
}

.btn-group {
  button.active, button.active:hover, button.active:active {
    background-color: $ruuby-blue;
    border-radius: 0;
    color: white;
  }
}

.new-booking-btn {
  float: right;
}

.spinner-container {
  margin: 25px 0;

  .sk-spinner {
    width: 27px;
    height: 27px;
    margin: 0 auto;

    .rotating-plane {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.create-booking, .create-customer {
  .btn-submit {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 20%;
    font-size: 1.5em;
    &.disabled {
      background: lightgrey;
      &:hover {
        background: lightgrey;
      }
    }
  }
  .field-edit {
    .btn-submit {
      margin-left: 5px;
      transform: none;
      width: 37px;
    }
  }
  .form-check {
    margin-top: 20px;
    label.form-check-label {
      p {
        display: inline;
        margin-left: 10px;
        font-family: "Helvetica Neue";
        text-transform: uppercase;
        font-weight: normal;
      }
    }

  }
}

.therapist-is-active-label {
  font-size: 16px;
  margin-left: 15px;
  padding: 10px;
  float: left;
  text-transform: uppercase;
}

.create-booking {
  margin-bottom: 50px;
  tr {
    .text-success {
      font-size: 0.65em;
    }
  }
  .spinner {
    margin-left: 50%;
  }
}

.create-customer {
  input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}

.main-details-lg {
  @include make-lg-column(9);
  .details-row {
    @include make-row;
    margin-bottom: 7px;

    .detail-col {
      @include make-lg-column(6);
    }
  }
}
.admin-side-menu {
  @include make-lg-column(3);
  .list-group {
    font-family: "nunito_sansbold";
    color: $ruuby-grey;
    .list-group-item {
      border-radius: 0;
      &.active {
        background: $ruuby-blue !important;
        border-color: $ruuby-blue !important;
      }
    }
  }
}

#customer {
  .customer-name-wrapper {
    display: block;
    position: relative;
    margin-bottom: 5px;
    h1 {
      position: relative;
      display: inline-block;
      margin-right: 10px;
      font-family: "nunito_sansbold";
      color: $ruuby-grey;
    }
    > div {
      position: relative;
      bottom: 5px;
    }
  }
  h3 {
    display: inline !important;
  }
  .addresses {
    tr {
      height: 50px;
      .btn-delete {
        @include button-variant(white, #d9534f, #d43f3a);
        margin-left: 5px;
        background: $ruuby-red;
        border-color: $ruuby-red;
        padding: 3px 7px 3px;
      }
    }
    .field-edit {
      display: table-cell;
      &:hover {
        .edit-icon-hover {
          display: inline-block !important;
        }
      }
    }
  }
  .cards {
    img {
      width: 60%;
    }
  }
}

td.total {
  font-weight: bolder;
  text-align: right;
}

.admin-nav {
  border-radius: 0;
  .logo-wrapper {
    margin-right: 15px;
    position: relative;
    width: 110px;
    padding: 10px 5px 5px 5px;
    svg {
      width: 100%;
      position: relative;
      fill: $ruuby-blue;
    }
  }
  li {
    a {
      font-family: "nunito_sansbold";
      font-size: 1.1em !important;
    }
  }
}

.input-number-width-50 {
  max-width: 50px;
}

.push-right-5 {
  margin-right: 5px;
}

.push-around-2 {
  margin: 2px 2px;
}

.ruuby-grey-mid {
  color: $ruuby-grey-mid;
}

.onboardedSwitch {
  &.bootstrap-switch-disabled {
    width: 44px !important;

    .bootstrap-switch-handle-on {
      opacity: 1 !important;
    }
  }
}

.react-datepicker-popper {
  z-index: 999;
}

@media (min-width: 1200px) {
  .container {
    width: 1350px;
  }
}