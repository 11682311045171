.ReactModal__Content {
  padding: 0px !important;
  margin: 0px !important;
  width: 50% !important;
  border: 0px !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
  text-align: center;
  background-color: red;

  .spinner-container {
    position: absolute;
    z-index: 150;
    left: 48%;
    top: 45%;
    transform: rotate(90deg);
  }

  .sk-spinner {
    display: flex;
    justify-content: center;
  }

  .modal_wrapper {
    .topNav {
      position: absolute;
      z-index: 100;
      right: 20px;
      font-size: 2.5em;
      .icons {
        .fa {
          padding: 0 5px;
          &:hover {
            cursor: pointer;
          }
        }
        .icons_overlay {
          background: white;
          position: absolute;
          right: 0;
          height: 100%;
          width: 100px;
          opacity: .8;
          display: none;
        }
      }
    }
    .ReactCrop {
      height: calc(100% - 55px);
      margin-top: 5px;
      img {
        height: 100%;
      }
    }
  
    &.persisting_image {
      .topNav {
        .icons {
          .icons_overlay {
            display: block;
          }
        }
      }
      .ReactCrop.ReactCrop--fixed-aspect:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background: white;
        opacity: .8;
        left: 0;
        z-index: 100;
      }
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 500 !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}