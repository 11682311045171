.booking-totals {
  table {
    tr.refund {
      color: red !important;
    }
  }
}

.new-transaction-modal {
  padding: 0 50px;
  .new-transaction-title {
    height: 10%;
    font-family: "nunito_sansbold";
    color: $ruuby-grey;

    .fa {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 2em;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .new-transaction-row {
    margin: 15px 0;
    .col-md-12 {
      .list-group {
        width: 100%;
        max-height: 300px;
        overflow: scroll;
        .list-group-item:hover {
          cursor: pointer;
        }
        .list-group-item.active {
          background: $ruuby-blue;
          border-color: $ruuby-blue;
        }
      }
    }

    .pound {
      font-size: 2em;
    }

    .btn, input {
      width: 100%;
      height: 50px;
      font-size: 1.2em;
    }

    .btn-submit {
      width: 50%;
      &.disabled {
        background: $ruuby-blue !important;
      }
    }

    .spinner {
      margin-left: 50%;
    }
  }
}

.error-row {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  color: red;
}

.form-group {
  margin: 10px 0;
}

.promo-controls {
  display: flex;
  padding-top: 10px;
}

.col-error {
  text-align: center;
  p {
    color: red;
  }
}