.therapist-container {
  @include make-row(15px);

  .main-section {
    @include make-lg-column(10);
    .app-details-row {
      .main-details {
        width: 100% !important;
        .main-details-row {
          .therapist-imgs {
            width: 100% !important;
          }
        }
      }
    }
  }

  .side-nav {
    @include make-lg-column(2);
    padding-top: 80px;
  }
}