.adjustments {
  .adjustments-header {
    margin-top: 10px;

    h3 {
      display: inline-block;
      margin-top: 0;
    }

    .new-adjustment {
      float: right;
      margin-left: 10px;
    }
  }

  tr.adjustments-row {
    td.amount {
      input {
        width: 100px;
      }
    }

    td.controls {
      .button-submit {
        @include button-variant(#5cb85c, white, #ccc);
        max-height: 35px;
        max-width: 35px;
        margin-right: 5px;
      }

      .button-cancel {
        @extend .btn;
        @include button-variant(white, #d9534f, #d9534f);
        max-height: 35px;
        max-width: 35px;
        margin-right: 5px;
        background: $ruuby-red;
        border-radius: 0;
        border-color: $ruuby-red;
      }

      .button-delete {
        @include button-variant(white, #d9534f, #d9534f);
        max-height: 35px;
        max-width: 35px;
        margin-right: 5px;
      }
    }
  }

  .no-adjustments {
    text-align: center;
    margin-top: 10px;
    color: #666;
    font-style: italic;
  }
}
