.customer-panel {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 0;
  padding: 10px;
  overflow: auto;

  .avatar {
    border-radius: 35px;
    padding-top: 12px;
    width: 70px;
    height: 70px;
    font-size: 35px;
    font-weight: bold;
    background: linear-gradient(to bottom, #9999AA 0%,#777788 100%);
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    float: left;
    margin-right: 10px;
  }

  h2 {
    margin: 0;
    font-size: 1.2em;
  }

  p {
    margin: 5px 0;
    font-size: 0.9em;
  }
}
