$booking-unpaid: #fff5fc;
$booking-paid: rgb(65, 65, 65);;

$status-cancelled: #808090;
$status-unconfirmed: rgb(238, 9, 9);
$status-confirmed: #9FCE58;
$status-on-way: #FF7D21;
$status-in-progress: #4DCCAA;
$status-completed: #0F7A99;

$ruuby-blue: #747b89;
$ruuby-blue-dark: #586b72;
$ruuby-grey: #565656;
$ruuby-grey-light: #e7eaeb;
$ruuby-grey-mid: #c0c0c0;
$ruuby-grey-bg: #f4f4f4;
$ruuby-pink: #f5ebe7;
$ruuby-red: #A10404;