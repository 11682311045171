.notes-container {
  margin-bottom: 50px;
  .notes-wrapper {
    .note {
      margin-bottom: 25px;
      border-bottom: 1px solid lightgray;
      p {
        margin: 0;
        &.header {
          font-weight: bold;
          font-size: .9em;
          color: grey;
        }
        &.comment {
          font-size: 1.1em;
          margin-bottom: 10px;
        }
      }

      .requires-action-container {
        text-align: right;
        margin: 5px 0;

        input {
          margin-right: 5px;
        }
      }
    }
  }

  .btn {
    border: 1px solid darkgrey;
    margin: 5px;

    &.add-btn {
      background: rgb(147, 187, 131);
    }
    &.cancel-btn {
      background: rgb(214, 158, 158);
    }
  }

  .note-create {
    textarea {
      width: 100%;
    }

  }
}
