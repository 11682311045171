.new-card-modal {
  padding: 0 50px;
  h2 {
    margin-bottom: 20px;
  }
  .fa-times {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
  }
  form {
    text-align: left;
    .row {
      margin: 15px 0;
    }
    .btn-submit {
      border: 1px solid lightgrey;
      width: 50%;
      height: 50px;
      margin-left: 50%;
      margin-top: 20px;
      transform: translateX(-50%);
      font-size: 1.5em;
    }
    .spinner {
      margin: 20px 0 0 50%;
    }
    .col-error {
      text-align: center;
      p {
        color: red;
      }
    }
  }
  .hosted-field {
    height: 45px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 0 10px;
  }
}