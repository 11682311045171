@font-face {
  font-family: 'handelsontwo';
  src: url('../assets/fonts/handelson-two-webfont.woff2') format('woff2'),
       url('../assets/fonts/handelson-two-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nunito_sansregular';
  src: url('../assets/fonts/nunitosans-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/nunitosans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'nunito_sanssemibold';
  src: url('../assets/fonts/nunitosans-semibold-webfont.woff2') format('woff2'),
       url('../assets/fonts/nunitosans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nunito_sansbold';
  src: url('../assets/fonts/nunitosans-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/nunitosans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
