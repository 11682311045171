.booking-container {
  @include make-row(15px);
}
.main-section {
  @include make-lg-column(10);

  .app-details-row {
    font-family: "nunito_sansregular";
  }

  h1 {
    font-family: "nunito_sansbold";
    color: $ruuby-grey;
  }

  .adjustments-header, .treatments-header {
    font-family: "nunito_sansbold";
    color: $ruuby-grey;
  }
  .treatments-header h3 {
    display: inline-block;
    margin: 0;
  }
}
.side-nav {
  @include make-lg-column(2);
  padding-top: 80px;

  .list-group {
    font-family: "nunito_sansbold";
    color: $ruuby-grey;
    .list-group-item {
      border-radius: 0;
      &.active {
        background: $ruuby-blue !important;
        border-color: $ruuby-blue !important;
      }
      &.disabled {
        color: $ruuby-grey-mid;
        background: white;
      }
    }
  }
}