.alert-modal {
  padding: 50px;

  .fa {
    font-size: 3em;
    color: rgb(92, 64, 16);
  }

  .alert-modal-info {
    p {
      font-size: 1.3em;
    }
  }

  .btn-row {
    margin: 25px 0;

    .btn {
      height: 40px;
      padding: 10px 25px;
      font-size: 1.2em;
    }

    .confirm {
      text-align: right;
    }
    .cancel {
      text-align: left;
    }
  }
}
