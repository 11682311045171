@import "../../styles/fonts";

.sendbird-conversation {
  height: 80%;

  &__messages &__messages-padding {
    overflow-y: auto;
  }
}

.sendbird-message-input .sendbird-message-input--textarea {
  overflow-y: auto;
}

.sendbird-message-content {
  height: auto;
  margin-bottom: 12px;

  &__left__avatar {
    max-width: 26px;
    max-height: 26px;
  }
}

.sendbird-label {
  font-family: "nunito_sansregular";
}

.sendbird-chat-header__right {
  display: none;
}
